import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Store } from '../store';

@Injectable({
  providedIn: 'root'
})
export class GrantsService {

  constructor(
    private http: HttpClient
  ) {
    // this.headers = new HttpHeaders();
    // this.headers = this.headers.append('XAuth-Token', Store.get('token'));
  }

  getAllGrants() {
    const headers = new HttpHeaders().set('XAuth-Token', Store.get('token'));
    return this.http.get(`${environment.api_url}api/voice/getallgranttypes`, {headers});
  }

  addGrant(grant) {
    const headers = new HttpHeaders().set('XAuth-Token', Store.get('token'));
    return this.http.post(`${environment.api_url}api/voice/addgranttype`, grant, {headers});
  }

  edit(grant) {
    const headers = new HttpHeaders().set('XAuth-Token', Store.get('token'));
    return this.http.post(`${environment.api_url}api/voice/editgranttype`, grant, {headers});
  }
  granteesByGrantType(grant_type_id: number) {
    const headers = new HttpHeaders().set('XAuth-Token', Store.get('token'));
    return this.http.get(`${environment.api_url}api/voice/getallgranteesbygranttype?grant_type_id=${grant_type_id}`, {headers});
  }

  delete(grant) {
    const headers = new HttpHeaders({
      'XAuth-Token': Store.get('token'),
      'Content-Type': 'application/json'
    });
    // console.log('Set headers ::', headers.keys());
    return this.http.post(`${environment.api_url}api/voice/deletegranttype`, grant, {headers});
  }
}
