import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Store } from '../store';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private http: HttpClient
  ) {
    this.loginEvent$ = new EventEmitter();
  }

  redirectUrl: string;
  loginEvent$: EventEmitter<any> ;

  isLoggedIn(): boolean {
    return Store.get('isLoggedIn') === 'true' ;
  }

  login(creds: any) {
    return this.http.post(`${environment.api_url}api/login/loginuser`, creds);
    // return this.http.post(`http://localhost:8888/lab/api/test`, creds);
  }

  updateProfile(user) {
    const headers = new HttpHeaders().set('XAuth-Token', Store.get('token'));
    return this.http.post(`${environment.api_url}api/voice/edituser`, user, {headers});
  }

  changePassword(data) {
    const headers = new HttpHeaders().set('XAuth-Token', Store.get('token'));
    return this.http.post(`${environment.api_url}api/voice/changeuserpassword`, data, {headers});
  }

  loggedOut(data) {
    const headers = new HttpHeaders({
      'XAuth-Token': Store.get('token'),
      'Content-Type': 'application/json'
    });
    return this.http.post(`${environment.api_url}api/voice/voiceuserloggedoutnotification`, data, {headers});
  }
}
