import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../providers/user.service';
import { User } from '../models/user';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { GranteesService } from '../providers/grantees.service';
import { CoreService } from '../providers/core.service';
import { Store } from '../store';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  users_: any;
  users: any;
  user: User = {
    user_name : '',
    email_address: '',
    phone_no: '',
    grantee_id: 0
  };
  loading = false;
  edit = false;
  grantees: any;
  preview: any;
  user_rights: any;
  modalReference: any;
  rgt: any;
  clp: any;
  rights_ids = [];
  filter: string;
  usr: any;
  usr_rights = [];
  userType = 'user';

  constructor(
    private  userService: UserService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private granteeService: GranteesService,
    private coreService: CoreService
  ) { }

  ngOnInit() {
    this.getUsers();
    this.getGrantees();

    this.usr_rights = this.coreService.getUserRights();
    this.usr = Store.getObject('user');
  }

  open(content) {
    if (!this.edit) {
      this.user = {
        user_name : '',
        email_address: '',
        phone_no: '',
        grantee_id: 0
      };
      this.preview = null;
    } else {
      this.preview = this.user.user_picture_url;
    }
    this.modalReference = this.modalService.open(content, {});
  }

  save() {
    this.loading = true;
    console.log('Trtying to save new user ::', this.user);
    if (this.edit) {
      const req = {
        user_id: this.user.user_id,
        user_name: this.user.user_name,
        email_address: this.user.email_address,
        phone_no: this.user.phone_no,
        user_image_base64: this.user.user_image_base_64,
        image_file_extension: this.user.image_file_extension
      };
      this.userService.edit(req).subscribe(
        (data) => {
          this.loading = false;
          console.log('Edit user ::', data);
          if (data) {
            this.modalReference.close();
            this.toastr.success('Update successful');
          } else {
            this.toastr.error('Update failed');
          }
        },
        (err) => {
          this.loading = false;
          this.toastr.error(err.error.Message, 'Http Error');
        }
      );
    } else {
      this.userService.add(this.user).subscribe(
        (data) => {
          this.toastr.success('User added', 'Success');
          this.modalReference.close();
          this.loading = false;
          this.users.push(data);
        },
        (err) => {
          this.loading = false;
          this.toastr.error(err.error.Message, 'Http Error');
        }
      );
    }
  }

  saveAdmin() {
    this.loading = true;
    console.log('Trying to save new admin ::', this.user);
    if (this.edit) {
      const req = {
        user_id: this.user.user_id,
        user_name: this.user.user_name,
        email_address: this.user.email_address,
        phone_no: this.user.phone_no,
        user_image_base64: this.user.user_image_base_64,
        image_file_extension: this.user.image_file_extension
      };
      this.userService.edit(req).subscribe(
        (data) => {
          this.loading = false;
          console.log('Edit user ::', data);
          if (data) {
            this.modalReference.close();
            this.toastr.success('Update successful');
          } else {
            this.toastr.error('Update failed');
          }
        },
        (err) => {
          this.loading = false;
          this.toastr.error(err.error.Message, 'Http Error');
        }
      );
    } else {
      this.userService.addAdmin(this.user).subscribe(
        (data) => {
          this.toastr.success('Sub Admin added', 'Success');
          this.loading = false;
          this.modalReference.close();
          this.users.push(data);
        },
        (err) => {
          this.loading = false;
          this.toastr.error(err.error.Message, 'Http Error');
        }
      );
    }
  }

  getUsers() {
    this.loading = true;
    this.userService.get().subscribe(
      (data) => {
        this.loading = false;
        console.log('user response ::', data);
        this.users_ = data;
        this.users = data;
      },
      (err) => {
        this.loading = false;
        console.log('user error ::', err.statusText);
        this.toastr.error(err.error.Message, 'Http error !!');
      }
    );
  }

  deleteUser(user, index) {
    const confirm = window.confirm('Are you sure ?');
    if (confirm) {
      this.loading = true;
      const req = JSON.stringify(user.user_id);
      this.userService.delete(req).subscribe(
        (data) => {
          this.loading = false;
          if (data) {
            this.toastr.success('Delete successful');
            this.users.splice(index, 1);
          } else {
            this.toastr.error('Delete failed');
          }
        },
        (err) => {
          this.loading = false;
          this.toastr.error(err.error.Message, 'Error deleting user');
        }
      );
    }
  }

  getGrantees() {
    this.granteeService.get().subscribe(
      (data) => {
        this.grantees = data;
        console.log('user grantees ::', data);
      },
      (err) => {
        console.log('User grantee error');
        this.toastr.error(err.error.Message, 'Grantee Error !!!');
      }
    );
  }

  onImageChange(event) {
    console.log('Image changed :: ', event);

    const file: File = event.target.files[0];
    const reader: FileReader = new FileReader();

    reader.readAsDataURL(file);

    const ctx = this;
    reader.onload = function (e) {
      console.log('the image', reader.result);
      ctx.preview = reader.result;
      console.log('the extension ::', ctx.coreService.getFileExtension(ctx.preview));
      ctx.user.user_image_base_64 = ctx.coreService.cleanUpBase64(reader.result);
      ctx.user.image_file_extension = ctx.coreService.getFileExtension(reader.result);
    };

  }

  editUserRights(content, user_id) {
    this.clp = {
      a0: false,
      a1: false,
      a2: false,
      a3: false,
      a4: false,
      a5: false,
      a6: false,
      a7: false,
      a8: false,
      a9: false,
      a10: false,
      a11: false,
      a12: false
    };
    this.rgt = {
      r91: false,
      r92: false,
      r93: false,
      r94: false,
      r95: false,
      r96: false,
      r97: false,
      r98: false,
      r99: false,
      r100: false,
      r101: false,
      r102: false,
      r103: false,
      r104: false,
      r105: false,
      r106: false,
      r107: false,
      r108: false,
      r109: false,
      r110: false,
      r111: false,
      r112: false,
      r113: false,
      r114: false,
      r115: false,
      r116: false,
      r117: false,
      r118: false,
      r119: false,
      r120: false,
      r121: false,
      r122: false,
      r123: false,
      r124: false,
      r125: false,
      r126: false,
      r127: false,
      r128: false,
      r129: false,
      r130: false,
      r131: false,
      r132: false,
      r133: false,
      r134: false,
      r135: false,
      r136: false,
      r137: false,
      r138: false,
      r139: false,
      r140: false
    };
    this.modalReference = this.modalService.open(content, {});
    this.loading = true;
    this.user_rights = [];
    this.userService.userRights(user_id).subscribe(
      (data: any) => {
        this.loading = false;
        console.log('The user rights ::', data);
        this.user_rights = data.UserPageRightsTree;
        this.populateRights(data.user_rights_ids);
        // console.log('The user has rights b4::', this.rights_ids);
        this.rights_ids = data.user_rights_ids.split(',');
        // console.log('The user has rights after::', this.rights_ids);
        this.rights_ids = this.rights_ids.map(Number);
        // console.log('The user has rights after::', this.rights_ids);
      },
      (err) => {
        this.loading = false;
        this.toastr.error(err.error.Message, 'Failed to get user rights');
      }
    );
  }

  rightsChange(event) {
    console.log('rights change ::', event.target.value);
    // console.log('rights change, val ::', val);
    console.log('The value now ::', this.rgt['r' + event.target.value]);
    const right = this.rgt['r' + event.target.value];
    const value = event.target.value;

    if (right) {
      // check for membership
      if (this.rights_ids.indexOf(Number(value)) === -1) {
        this.rights_ids.push(Number(value));
      }
    } else {
      if (this.rights_ids.indexOf(Number(value)) > -1) {
        this.rights_ids.splice(this.rights_ids.indexOf(Number(value)), 1);
      }
    }
    console.log('THe current rights ::', this.rights_ids);
  }

  updateRights() {
    const req = {
      user_id: this.user.user_id,
      rights_string: this.rights_ids.join(',')
    };
    console.log('The rights data ::', req);
    this.loading = true;
    this.userService.updateUserRights(req).subscribe(
      (data) => {
        this.loading = false;
        this.toastr.success('User rights updated');
        this.modalReference.close();
      },
      (err) => {
        this.loading = false;
        this.toastr.error(err.error.Message, 'Rights update failed');
      }
    );
  }
  populateRights(rights_ids: string) {
    if (rights_ids !== null) {
      const rights = rights_ids.split(',');
      for (let i = 0; i < rights.length; i++) {
        const rt = rights[i].toString();
        this.rgt['r' + rt] = true;
      }
    }
    // console.log('The rights pop ::', this.rgt);
  }

  quickFilter(): void {
    const ctx = this;

    const searchList = this.users_;
    let filteredList;
    filteredList = searchList.filter(function (us) {
      us.phone_no = (us.phone_no == null) ? '' : us.phone_no;
      return us.user_name.toLowerCase().includes(ctx.filter.toLowerCase()) ||
        us.phone_no.includes(ctx.filter) ||
        us.email_address.toLowerCase().includes(ctx.filter.toLowerCase());
    });

    this.users = filteredList;
  }

  hasRight(right_id: string): boolean {
    if (this.usr_rights.indexOf(right_id) > -1) {
      return true;
    }

    return false;
  }
}
