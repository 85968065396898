import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { GranteesService } from '../providers/grantees.service';
import { GrantsService } from '../providers/grants.service';
import { TargetGroupsService } from '../providers/target-groups.service';
import { Grantee } from '../models/grantee';
import { TargetGroup } from '../models/target-group';
import { ToastrService } from 'ngx-toastr';
import { CoreService } from '../providers/core.service';
import { ResourceService } from '../providers/resource.service';

@Component({
  selector: 'app-grantee',
  templateUrl: './grantee.component.html',
  styleUrls: ['./grantee.component.css']
})
export class GranteeComponent implements OnInit {

  grantees_: any;
  grantees: any;
  targetGroups: TargetGroup[];
  grants: any;
  loading = false;
  edit = false;
  grantee: Grantee = {
    grantee_name: '',
    contact_person: '',
    contact_phone: '',
    company_address: '',
    company_email: '',
    alias: '',
    grantee_image_changed: false,
    grantee_profile_changed: false
  };
  preview: any;
  modalReference: any;
  users: any;
  granteeTg: any;
  document_url: any;
  file_name: string;
  filter: string;
  user_rights = [];
  user_level: number;

  constructor(
    private modalService: NgbModal,
    private granteeService: GranteesService,
    private grantService: GrantsService,
    private targetGroupService: TargetGroupsService,
    private toastr: ToastrService,
    private coreService: CoreService,
    private resourceService: ResourceService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.getGrantees();
    this.getAllGrants();
    this.getTargetGroups();
    this.user_rights = this.coreService.getUserRights();
    this.user_level = this.coreService.getUserLevelId();
  }

  open(content) {
    if (!this.edit) {
      this.grantee = {
        grantee_name: '',
        contact_person: '',
        contact_phone: '',
        company_address: '',
        company_email: '',
        alias: ''
      };
      this.preview = null;
    } else {
      this.preview = this.grantee.grantee_image_url;
    }
    /*this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then(
      (results) => {
        console.log('Modal closed');
      },
      (reason) => {
        console.log('Closed with reason :', reason);
      }
    );*/
    this.modalReference = this.modalService.open(content, {});
  }

  getGrantees() {
    this.loading = true;
    this.granteeService.get().subscribe(
      (data) => {
        this.loading = false;
        console.log('Grantee response ::', data);
        this.grantees_ = data;
        this.grantees = data;
      },
      (err) => {
        this.loading = false;
        // alert('Error fetching grantees');
        console.log('Error getting grantees ::', err);
        this.toastr.error(err.error.Message, 'Error getting grantees');
      }
    );
  }

  getAllGrants() {
    this.grantService.getAllGrants().subscribe(
      (data) => {
        console.log('The gramts ::', data);
        this.grants = data;
      }
    );
  }

  getTargetGroups() {
    this.targetGroupService.get().subscribe(
      (data: TargetGroup[]) => {
        console.log('The target groups ::', data);
        this.targetGroups = data;
      },
      (err) => {
        console.log('Error fetching target groups');
      }
    );
  }

  save() {
    this.loading = true;
    console.log('tryna save grantee ::', this.grantee);
    if (this.edit) {
      this.granteeService.edit(this.grantee).subscribe(
        (data) => {
          this.loading = false;
          if (data) {
            this.modalReference.close();
            this.toastr.success('Update successful');
          } else {
            this.toastr.error('Update failed');
          }
        },
        (err) => {
          this.loading = false;
          this.toastr.error(err.error.Message, 'Grantee Update Error !!');
        }
      );
    } else {
      this.granteeService.add(this.grantee).subscribe(
        (data) => {
          this.loading = false;
          console.log('Add grantee resp ::', data);
          this.modalReference.close();
          this.toastr.success('Grantee added successfully');
          this.grantees.push(data);
        },
        (err) => {
          this.loading = false;
          this.toastr.error(err.error.Message, 'Http Error !!');
        }
      );
    }
  }

  deleteGrantee(gr, index) {
    const confirm = window.confirm('Are you sure ?');
    if (confirm) {
      this.loading = true;
      const req = JSON.stringify(gr.grantee_id);
      this.granteeService.delete(req).subscribe(
        (data) => {
          this.loading = false;
          if (data) {
            this.toastr.success('Delete successful');
            this.grantees.splice(index, 1);
          } else {
            this.toastr.error('Delete failed');
          }
        },
        (err) => {
          this.loading = false;
          // this.toastr.error(err.statusText, 'Http Error !!');
          this.toastr.error(err.error.Message, 'Http Error !!');
          console.log('Grantee delete failed ::', err);
        }
      );
    }
  }

  onImageChange(event) {
    // console.log('Image changed :: ', event);

    const file: File = event.target.files[0];
    const reader: FileReader = new FileReader();

    reader.readAsDataURL(file);

    const ctx = this;
    reader.onload = function (e) {
      // console.log('the image', reader.result);
      ctx.preview = reader.result;
      // console.log('the extension ::', ctx.coreService.getFileExtension(ctx.preview));
      ctx.grantee.grantee_image_base_64 = ctx.coreService.cleanUpBase64(reader.result);
      ctx.grantee.image_file_extension = ctx.coreService.getFileExtension(reader.result);
      ctx.grantee.grantee_image_changed = true;
    };

  }

  onFileChange(event) {
    console.log('File changed :: ', event);

    const file: File = event.target.files[0];
    const reader: FileReader = new FileReader();
    this.file_name = file.name;

    reader.readAsDataURL(file);

    const ctx = this;
    reader.onload = function (e) {
      // console.log('the file type', ctx.coreService.getFileType(file));
      ctx.grantee.grantee_profile_base_64 = ctx.coreService.cleanUpBase64(reader.result);
      // ctx.grantee.grantee_profile_file_extension = ctx.coreService.getFileExtension(reader.result);
      ctx.grantee.grantee_profile_file_extension = ctx.coreService.getFileExtension_revised(file);
      ctx.grantee.grantee_profile_changed = true;
    };

  }


  getUsersByGrantee(content, grantee_id) {
    this.modalService.open(content);
    this.loading = true;
    this.granteeService.usersByGrantee(grantee_id).subscribe(
      (data) => {
        this.loading = false;
        this.users = data;
      },
      (err) => {
        this.loading = false;
        this.toastr.error(err.statusText, 'Failed to get users !');
      }
    );
  }

  getTGByGrantee(content, grantee_id: number) {
    this.modalService.open(content, {});
    this.loading = true;
    this.resourceService.tgByGrantee(grantee_id).subscribe(
      (data) => {
        this.loading = false;
        this.granteeTg = data;
      },
      (err) => {
        this.loading = false;
        this.toastr.error(err.error.Message, 'Failed to fetch TGs');
      }
    );
  }

  viewDoc(content, url) {
    this.document_url = this.sanitizer.bypassSecurityTrustResourceUrl(encodeURI(`https://docs.google.com/gview?url=${url}&embedded=true`));
    this.modalService.open(content, { size: 'lg'});
  }

  quickFilter(): void {
    const ctx = this;

    const searchList = this.grantees_;
    let filteredList;
    filteredList = searchList.filter(function (gr) {
      return gr.grantee_name.toLowerCase().includes(ctx.filter.toLowerCase()) ;
    });

    this.grantees = filteredList;
  }

  hasRight(right_id: string): boolean {
    if (this.user_rights.indexOf(right_id) > -1) {
      return true;
    }

    return false;
  }
}
