import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TargetGroupsService } from '../providers/target-groups.service';
import { CoreService } from '../providers/core.service';

import { TargetGroup } from '../models/target-group';

@Component({
  selector: 'app-target-group',
  templateUrl: './target-group.component.html',
  styleUrls: ['./target-group.component.css']
})
export class TargetGroupComponent implements OnInit {

  target_groups: any;
  targetGroup: TargetGroup = {
    target_gp_name : '',
  };
  loading = false;
  edit = false;
  grantees: any;
  modalReference: any;
  tgGrantees: any;
  users: any;
  user_rights = [];
  user_level: number;

  constructor (
    private modalService: NgbModal,
    private toastr: ToastrService,
    private targetGroupService: TargetGroupsService,
    private coreService: CoreService
  ) { }

  ngOnInit() {
    this.getTargetGroups();
    this.user_rights = this.coreService.getUserRights();
    this.user_level = this.coreService.getUserLevelId();
    console.log('the user level ::', this.user_level);
  }

  open(content) {
    if (!this.edit) {
      this.targetGroup = {
        target_gp_name: ''
      };
    }
    /*this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then(
      (results) => {
        console.log('Modal closed');
      },
      (reason) => {
        console.log('Closed with reason :', reason);
      }
    );*/
    this.modalReference = this.modalService.open(content, {});
  }

  save() {
    this.loading = true;
    console.log('Tried to save ::', this.targetGroup);
    if (this.edit) {
      this.targetGroupService.edit(this.targetGroup).subscribe(
        (data) => {
          this.loading = false;
          this.modalReference.close();
          this.toastr.success('Update successful');
        },
        (err) => {
          this.loading = false;
          this.toastr.error(err.error.Message, 'Http Error !');
        }
      );
    } else {
      this.targetGroupService.add(this.targetGroup).subscribe(
        (data) => {
          this.loading = false;
          this.target_groups.push(data);
          this.modalReference.close();
          this.toastr.success('Target group added successfully');
        },
        (err) => {
          this.loading = false;
          this.toastr.error(err.error.Message, 'Http Error !');
        }
      );
    }
  }

  getTargetGroups() {
    this.loading = true;
    this.targetGroupService.get().subscribe(
      (data) => {
        this.loading = false;
        console.log('The target groups ::', data);
        this.target_groups = data;
      },
      (err) => {
        this.toastr.error(err.error.Message, 'Failed to ge target groups!');
        console.log('Failed to get target groups ::', err.error.Message);
      }
    );
  }

  deleteTargetGroup(tg, index) {
    const confirm = window.confirm('Are you sure ?');
    if (confirm) {
      this.loading = true;
      const req = JSON.stringify(tg.target_gp_id);
      this.targetGroupService.delete(req).subscribe(
        (data) => {
          this.loading = false;
          if (data) {
            this.toastr.success('Delete successful');
            this.target_groups.splice(index, 1);
          } else {
            this.toastr.error('Delete failed');
          }
        },
        (err) => {
          this.loading = false;
          this.toastr.error(err.error.Message, 'HTTP Error !!');
        }
      );
    }
  }

  getGranteesByTG(content, target_gp_id) {
    this.modalReference = this.modalService.open(content, {});
    this.loading = true;
    this.tgGrantees = null;
    this.targetGroupService.granteesByTG(target_gp_id).subscribe(
      (data) => {
        this.loading = false;
        this.tgGrantees = data;
      },
      (err) => {
        this.loading = false;
        this.modalReference.close();
        this.toastr.error(err.error.Message, 'Failed to fetch grantees');
      }
    );
  }

  getUsersByTG(content, target_gp_id) {
    this.modalService.open(content, {});
    this.loading = true;
    this.targetGroupService.usersByTG(target_gp_id).subscribe(
      (data) => {
        this.loading = false;
        this.users = data;
        console.log('The TG users ::', data);
      },
      (err) => {
        this.loading = false;
        this.toastr.error(err.error.Message, 'Failed to get users !!');
      }
    );
  }

  hasRight(right_id: string): boolean {
    if (this.user_rights.indexOf(right_id) > -1) {
      return true;
    }

    return false;
  }

}
