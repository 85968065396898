import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../providers/login.service';
import * as crypto from 'crypto-js';
import { Store } from '../store';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loading = false;
  timer: any;
  error = false;
  message = '';
  usr = {
    user_id: '',
    user_pwd: ''
  };

  constructor(
    private router: Router,
    private loginService: LoginService
  ) { }

  ngOnInit() {
    this.usr = {
      user_id: '',
      user_pwd: ''
    };
    if (this.loginService.isLoggedIn()) {
      const redirectUrl = (this.loginService.redirectUrl) ? this.loginService.redirectUrl : '/';
      this.router.navigate([redirectUrl]);
    }
    console.log('the saved page::', this.loginService.redirectUrl);
  }

  login() {
    this.loading = true;
    const creds = {
      user_id: this.usr.user_id,
      user_pwd: crypto.SHA256(this.usr.user_pwd).toString()
    };

    this.loginService.login(creds).subscribe(
      (data: any) => {
        this.loading = false;
        console.log('The response ::', data);
        Store.add('isLoggedIn', 'true');
        Store.addObject('user', data);
        Store.add('token', data.access_token);
        Store.add('loginTime', Math.ceil(new Date().getTime() / 1000).toString());
        let redirectUrl = (this.loginService.redirectUrl && this.loginService.redirectUrl !== undefined) ? this.loginService.redirectUrl : '/';
        redirectUrl = (redirectUrl === '/login') ? '/' : redirectUrl;
        this.router.navigate([redirectUrl]);

        this.loginService.loginEvent$.emit();
      },
      (err) => {
        this.loading = false;
        console.log('Login error ::', err.statusText);
        this.error = true;
        this.message = 'Wrong username/password';
        // this.message = err.error.Message;
      }
    );
  }

  guestLogin() {
    this.usr.user_id = 'guest@nomail.com';
    this.usr.user_pwd = '1234';

    this.login();
  }
}
