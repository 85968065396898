import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Store } from '../store';

@Injectable({
  providedIn: 'root'
})
export class GranteesService {

  constructor(
    private http: HttpClient
  ) { }

  get() {
    const headers = new HttpHeaders().set('XAuth-Token', Store.get('token'));
    return this.http.get(`${environment.api_url}api/voice/getallgrantees`, {headers});
  }

  add(gr) {
    const headers = new HttpHeaders().set('XAuth-Token', Store.get('token'));
    return this.http.post(`${environment.api_url}api/voice/addgrantee`, gr, {headers});
  }

  edit(gr) {
    const headers = new HttpHeaders().set('XAuth-Token', Store.get('token'));
    return this.http.post(`${environment.api_url}api/voice/editgrantee`, gr, {headers});
  }

  delete(gr) {
    const headers = new HttpHeaders({
      'XAuth-Token': Store.get('token'),
      'Content-Type': 'application/json'
    });
    return this.http.post(`${environment.api_url}api/voice/deletegrantee`, gr, {headers});
  }

  usersByGrantee(grantee_id) {
    const headers = new HttpHeaders().set('XAuth-Token', Store.get('token'));
    return this.http.get(`${environment.api_url}api/voice/getusersbygrantee?grantee_id=${grantee_id}&fs_timestamp=0`, {headers});
  }

}
