import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '../store';
import { LoginService } from '../providers/login.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from '../models/user';
import { ToastrService } from 'ngx-toastr';
import { CoreService } from '../providers/core.service';
import * as crypto from 'crypto-js';

const { version: appVersion } = require('../../../package.json');

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {

  constructor(
    private router: Router,
    private loginService: LoginService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private coreService: CoreService
  ) {}

  @Output() langswitch: EventEmitter<string> = new EventEmitter();
  path: string;
  preview: any;
  loading = false;
  lang = 'english';
  user: User = {
    user_name: '',
    email_address: '',
    phone_no: '',
    user_picture_url: ''
  };
  modalReference: any;
  user_rights = [];
  user_level: number;
  version: string;
  pwd = {
    old_pwd: '',
    new_pwd: ''
  };
  lgbt = {
    first: 'no',
    second: 'no'
  };
  // @ViewChild('lgbtBtn') lgbtBtn: ElementRef;
  showLgbtOne = true;
  showLgbtTwo = false;
  showLgbt = true;
  isNavCollapsed = true;
  menu = {
    home: 'Home',
    grants: 'Grants',
    rights_holders: 'Rights Holders',
    grantees: 'Grantees',
    grantee_profiles: 'Grantee Profiles',
    users: 'Users',
    media: 'Media',
    publications: 'Publications',
    success_stories: 'Success Stories',
    my_drive: 'My Drive',
    statistics: 'Statistics',
    logout: 'Log out',
    language: 'Language'
  };
  menu_en = {
    home: 'Home',
    grants: 'Grants',
    rights_holders: 'Rights Holders',
    grantees: 'Grantees',
    grantee_profiles: 'Grantee Profiles',
    users: 'Users',
    media: 'Media',
    publications: 'Publications',
    success_stories: 'Success Stories',
    my_drive: 'My Drive',
    statistics: 'Statistics',
    logout: 'Log out',
    language: 'Language'
  };
  menu_lug = {
    home: 'Ewaka',
    grants: 'Ensimbi Eziwebwayo',
    rights_holders: 'Abalina eddembe lyobuntu',
    grantees: 'Abafuna Ensimbi',
    grantee_profiles: 'Ebikwata kubafuna ensimbi',
    users: 'Abakozesa',
    media: 'Media',
    publications: 'Ebiwandiiko',
    success_stories: 'Emboozi z\'obuwanguzi',
    my_drive: 'Fayilo',
    statistics: 'Ebibalo',
    logout: 'Fuluma',
    language: 'Olulimi'
  };
  menu_swa = {
    home: 'Nyumbani',
    grants: 'Ruzuku',
    rights_holders: 'Wenye Haki',
    grantees: 'Wafadhili',
    grantee_profiles: 'Wasifi wa ruzuku',
    users: 'Watumiaji',
    media: 'Media',
    publications: 'Wachapisho',
    success_stories: 'Hadithi za mafanikio',
    my_drive: 'Mafaili',
    statistics: 'Twakimu',
    logout: 'Kuondoka',
    language: 'Lugha'
  };

  ngOnInit() {
    this.version = appVersion;
    // console.log('The VERSION ::', this.version);
    this.path = this.router.url;
    this.user = Store.getObject('user');
    const lang = Store.getObject('lang');
    if (lang && lang !== '') {
      this.lang = lang;
      this.switchLanguage();
    }
    this.user_level = this.coreService.getUserLevelId();
    if (this.user.user_rights_ids_string) {
      this.user_rights = this.user.user_rights_ids_string.split(',');
      // console.log('the rights ::', this.user_rights);
    }
  }

  open(content) {
    this.modalReference = this.modalService.open(content, {});
    if (this.user.user_picture_url !== '') {
      this.preview = this.user.user_picture_url;
    }
  }

  updateProfile() {
    this.loading = true;
    const req = {
      user_id: this.user.user_id,
      user_name: this.user.user_name,
      email_address: this.user.email_address,
      phone_no: this.user.phone_no,
      user_image_base64: this.user.user_image_base_64,
      image_file_extension: this.user.image_file_extension
    };
    this.loginService.updateProfile(req).subscribe(
      (data: any) => {
        this.loading = false;
        // console.log('Update profile response ::', data);
        this.toastr.success('Update successful');
        this.user.user_picture_url = this.preview;
        Store.addObject('user', this.user);
        this.modalReference.close();
      },
      (err) => {
        this.loading = false;
        this.toastr.error(err.statusText, 'Update failed');
      }
    );
  }

  onImageChange(event) {
    // console.log('Image changed :: ', event);

    const file: File = event.target.files[0];
    const reader: FileReader = new FileReader();

    reader.readAsDataURL(file);

    const ctx = this;
    reader.onload = function (e) {
      // console.log('the image', reader.result);
      ctx.preview = reader.result;
      // console.log('the extension ::', ctx.coreService.getFileExtension(ctx.preview));
      ctx.user.user_image_base_64 = ctx.coreService.cleanUpBase64(reader.result);
      ctx.user.image_file_extension = ctx.coreService.getFileExtension(reader.result);
    };

  }

  switchLanguage () {
    Store.addObject('lang', this.lang);
    switch (this.lang) {
      case 'swahili':
        this.menu = this.menu_swa;
        break;
      case 'luganda':
        this.menu = this.menu_lug;
        break;
      default:
        this.menu = this.menu_en;
        break;
    }
    this.langswitch.emit(this.lang);
  }


  logout() {
    // console.log('Logging out');
    // logout user

    const req = JSON.stringify(this.user.user_id);
    this.loginService.loggedOut(req).subscribe(
      (data) => {
        // console.log('The logged out call return ::', data);
      },
      (err) => {
        this.toastr.error(err.statusText, 'Logged out fail::');
        // console.log('Logged out error ::', err);
      }
    );

    Store.clear();
    this.loginService.redirectUrl = this.path;
    this.router.navigate(['/login']);
  }

  hasRight(right_id: string): boolean {
    if (this.user_rights.indexOf(right_id) > -1) {
      return true;
    }

    return false;
  }

  changePassword() {
    this.loading = true;
    const req = {
      old_pwd: crypto.SHA256(this.pwd.old_pwd).toString(),
      new_pwd: crypto.SHA256(this.pwd.new_pwd).toString(),
      user_id: this.user.user_id
    };
    this.loginService.changePassword(req).subscribe(
      (data) => {
        this.loading = false;
        this.toastr.success('Password update successful');
        this.modalReference.close();
        // console.log('change pwd response ::', data);
      },
      (err) => {
        this.loading = false;
        // console.log('Error changing password ::', err);
        this.toastr.error(err.error.Message, 'Password change Error !!');
      }
    );
  }

  lgbtChange() {
    if (this.lgbt.first === 'yes' && this.lgbt.second === 'no') {
      this.showLgbtOne = false;
      this.showLgbtTwo = true;
      this.showLgbt = false;
    }
    if (this.lgbt.first === 'yes' && this.lgbt.second === 'yes') {
      this.modalReference.close();
      this.showLgbtOne = true;
      this.showLgbtTwo = false;
      this.showLgbt = true;
    }
    if (this.lgbt.first === 'no') {
      this.lgbt.second = 'no';
      this.modalReference.close();
      this.showLgbt = false;
    }
    Store.addObject('lgbtPrefs', this.lgbt);
  }

}
