import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ResourceService } from '../providers/resource.service';
import { GranteesService } from '../providers/grantees.service';
import { TargetGroupsService } from '../providers/target-groups.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Resource } from '../models/resource';
import { Store } from '../store';
import { CoreService } from '../providers/core.service';

@Component({
  selector: 'app-publications',
  templateUrl: './publications.component.html',
  styleUrls: ['./publications.component.css']
})
export class PublicationsComponent implements OnInit {

  constructor(
    private resourceService: ResourceService,
    private granteeService: GranteesService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private tgService: TargetGroupsService,
    private coreSevice: CoreService,
    private sanitizer: DomSanitizer
  ) { }

  loading = false;
  edit = false;
  grantees = [];
  target_groups: any;
  resource: Resource = {
    resource_type_id: 3,
    resource_file_name: '',
    grantee_id: 0,
    target_gp_id: 0,
    user_id: 0,
    upload_date: '',
    resource_data: ''
  };
  preview: any;
  file: File;
  resources_ = [];
  resources = [];
  showGrantees = true;
  showResources = false;
  gr_selected = '';
  document: any;
  document_url: any;
  modalReference: any;
  filter: any;
  user_rights = [];
  @ViewChild('lgbtBtn') lgbtBtn: ElementRef;
  showLgbtOne = true;
  showLgbtTwo = false;
  showLgbt = true;
  lgbt = {
    first: 'no',
    second: 'no'
  };

  ngOnInit() {
    this.getGrantees();
    // this.getTargetGroups();
    this.user_rights = this.coreSevice.getUserRights();
  }

  open(content) {
    /*this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then(
      (results) => {
        console.log('Modal closed');
      },
      (reason) => {
        console.log('Closed with reason :', reason);
      }
    );*/
    this.preview = null;
    this.modalReference = this.modalService.open(content);
  }

  viewDoc(content) {
    console.log('The document', this.document);
    // this.document_url = this.sanitizer.bypassSecurityTrustResourceUrl(this.document.resource_url);
    this.document_url = this.sanitizer.bypassSecurityTrustResourceUrl(encodeURI(`https://docs.google.com/gview?url=${this.document.resource_url}&embedded=true`));
    if (this.hasRight('116')) {
      this.modalService.open(content, { size: 'lg'});
      this.resourceViewed();
    }
  }

  addResource() {
    this.loading = true;
    const formData = new FormData();
    formData.append('resource_type_id', this.resource.resource_type_id.toString());
    formData.append('resource_file_name', this.resource.resource_file_name);
    formData.append('grantee_id', this.resource.grantee_id.toString());
    formData.append('target_gp_id', this.resource.target_gp_id.toString());
    formData.append('user_id', Store.getObject('user').user_id);
    formData.append('resource_data', this.file);

    this.resourceService.add(formData).subscribe(
      (data) => {
        this.loading = false;
        console.log('Resource add resp ::', data);
        this.toastr.success('Resource upload successful');
        this.resources.push(data);
        this.modalReference.close();
      },
      (err) => {
        this.loading = false;
        console.log('Resource add failed ::', err);
        this.toastr.error( err.error.Message, 'Resource add failed !');
      }
    );
  }

  getGrantees() {
    this.loading = true;
    this.granteeService.get().subscribe(
      (data: any) => {
        this.loading = false;
        this.grantees = data;
        console.log('The grantees ::', this.grantees);
      },
      (err) => {
        this.loading = false;
        this.toastr.error(err.statusText, 'Http Error !!');
      }
    );
  }

  granteeSelected(grantee_id) {
    console.log('getting TGs for ::', grantee_id);
    this.loading = true;
    this.resourceService.tgByGrantee(grantee_id).subscribe(
      (data) => {
        this.loading = false;
        console.log('target gps for grantee ::', data);
        this.target_groups = data;
      },
      (err) => {
        console.log('Failed to grt tgs ::', err.error.Message);
        this.toastr.error(err.error.Message, 'Error getting TGs');
      }
    );
  }

  getResourcesByGranteeResourceType(grantee_id) {
    this.loading = true;
    if (grantee_id === 138) {
      this.showLgbt = false;
      const lp = Store.getObject('lgbtPrefs');
      if (lp == null) {
        this.lgbtBtn.nativeElement.click();
      } else {
        this.lgbt = lp;
        if (lp.first === 'yes' && lp.second === 'yes') {
          this.showLgbt = true;
        } else {
          this.showLgbt = false;
        }
      }
    }
    this.resourceService.getAllResourcesByGranteeResourceType(grantee_id, 3).subscribe(
      (data: any) => {
        console.log('Resources response ::', data);
        this.loading = false;
        this.resources_ = data;
        this.resources = data;
        // sort data
        this.resources_.sort(function (a, b) {
          return a.resource_file_format - b.resource_file_format;
        });
        this.resources.sort(function (a, b) {
          return a.resource_file_format - b.resource_file_format;
        });
      },
      (err) => {
        this.loading = false;
        this.toastr.error(err.statusText, 'Res get failed !!');
      }
    );
  }

  deleteResource(resource, index) {
    const confirm = window.confirm('Are you sure ?');
    if (confirm) {
      this.loading = true;
      const req = JSON.stringify(resource.resource_id);
      this.resourceService.delete(req).subscribe(
        (data) => {
          this.loading = false;
          this.toastr.success('Resource deleted successfully');
          this.resources.splice(index, 1);
        },
        (err) => {
          this.loading = false;
          this.toastr.error(err.error.Message, 'Resource delete failed');
        }
      );
    }
  }

  addToDrive(id) {
    this.loading = true;
    const req = JSON.stringify(id);
    this.resourceService.addToDrive(req).subscribe(
      (data) => {
        this.loading = false;
        this.toastr.success('Resource added to drive successfully');
      },
      (err) => {
        this.loading = false;
        this.toastr.error(err.error.Message, 'Failed to add to drive');
      }
    );
  }

  onFileChange(event) {
    console.log('File changed :: ', event);

    const file: File = event.target.files[0];
    if (file) {
      this.file = file;
      this.resource.resource_file_name = file.name;
      console.log('the file name ::', file.name);
      console.log('the file type ::', this.coreSevice.getFileType(file));
      if (this.coreSevice.getFileType(file) === 'image') {
        this.preview = file;
        const reader: FileReader = new FileReader();

        reader.readAsDataURL(file);

        const ctx = this;
        reader.onload = function (e) {
          ctx.preview = reader.result;
        };
      }
    }
  }

  quickFilter(event): void {
    const ctx = this;
    // console.log('trying to search', this.filter);

    const searchList = this.resources_;
    let filteredList;
    filteredList = searchList.filter(function (rs) {
      return rs.resource_file_name.toLowerCase().includes(ctx.filter.toLowerCase()) ;
    });

    this.resources = filteredList;
  }

  resourceViewed() {
    const req = JSON.stringify(this.document.resource_id);
    this.resourceService.resourceViewed(req).subscribe(
      (data) => {
        console.log('Resource viewed ::', data);
      },
      (err) => {
        this.toastr.error(err.error.Message, 'Resource viewed error !!');
      }
    );
  }

  resourceDownloaded() {
    const req = JSON.stringify(this.document.resource_id);
    this.resourceService.resourceDownloaded(req).subscribe(
      (data) => {
        console.log('Resource downloaded ::', data);
      },
      (err) => {
        this.toastr.error(err.error.Message, 'Resource downloaded error !!');
      }
    );
  }

  hasRight(right_id: string): boolean {
    if (this.user_rights.indexOf(right_id) > -1) {
      return true;
    }

    return false;
  }

  lgbtChange() {
    if (this.lgbt.first === 'yes' && this.lgbt.second === 'no') {
      this.showLgbtOne = false;
      this.showLgbtTwo = true;
      this.showLgbt = false;
    }
    if (this.lgbt.first === 'yes' && this.lgbt.second === 'yes') {
      this.modalReference.close();
      this.showLgbtOne = true;
      this.showLgbtTwo = false;
      this.showLgbt = true;
    }
    if (this.lgbt.first === 'no') {
      this.lgbt.second = 'no';
      this.modalReference.close();
      this.showLgbt = false;
    }
    Store.addObject('lgbtPrefs', this.lgbt);
  }

}
