import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ResourceService } from '../providers/resource.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-drive',
  templateUrl: './drive.component.html',
  styleUrls: ['./drive.component.css']
})
export class DriveComponent implements OnInit {

  constructor(
    private resourceService: ResourceService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer
  ) { }

  loading = false;
  resources: any;
  document: any;
  document_url: any;

  ngOnInit() {
    this.getResources();
  }

  getResources() {
    this.loading = true;
    this.resourceService.getDriveResources().subscribe(
      (data) => {
        this.loading = false;
        this.resources = data;
      },
      (err) => {
        this.loading = false;
        this.toastr.error(err.error.Message, 'Failed to get resources');
      }
    );
  }

  removeResource(id, index) {
    const confirm = window.confirm('Are you sure');
    if (confirm) {
      this.loading = true;
      const req = JSON.stringify(id);
      this.resourceService.removeDriveResource(req).subscribe(
        (data) => {
          this.loading = false;
          this.toastr.success('Resource removed successfully');
          this.resources.splice(index, 1);
        },
        (err) => {
          this.loading = false;
          this.toastr.error(err.error.Message, 'Failed to remove resource');
        }
      );
    }
  }

  viewDoc(content) {
    // this.document_url = this.sanitizer.bypassSecurityTrustResourceUrl(this.document.resource_url);
    this.document_url = this.sanitizer.bypassSecurityTrustResourceUrl(encodeURI(`https://docs.google.com/gview?url=${this.document.resource_url}&embedded=true`));
    this.modalService.open(content, { size: 'lg'});
  }

}
