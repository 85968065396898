import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Store } from '../store';

@Injectable({
  providedIn: 'root'
})
export class TargetGroupsService {

  constructor(
    private http: HttpClient
  ) { }

  get() {
    const headers = new HttpHeaders().set('XAuth-Token', Store.get('token'));
    // return this.http.get('../../assets/data/target-groups.json');
    return this.http.get(`${environment.api_url}api/voice/gettargetgroups`, {headers});
  }

  add(tg) {
    const headers = new HttpHeaders().set('XAuth-Token', Store.get('token'));
    return this.http.post(`${environment.api_url}api/voice/addtargetgroup`, tg, {headers});
  }

  edit(tg) {
    const headers = new HttpHeaders().set('XAuth-Token', Store.get('token'));
    return this.http.post(`${environment.api_url}api/voice/edittargetgroup`, tg, {headers});
  }

  delete(tg) {
    const headers = new HttpHeaders({
      'XAuth-Token': Store.get('token'),
      'Content-Type': 'application/json'
    });
    return this.http.post(`${environment.api_url}api/voice/deletetargetgroup`, tg, {headers});
  }

  granteesByTG(target_gp_id) {
    const headers = new HttpHeaders().set('XAuth-Token', Store.get('token'));
    return this.http.get(`${environment.api_url}api/voice/getallgranteesbytargetgp?target_gp_id=${target_gp_id}`, {headers});
  }

  usersByTG(target_gp_id) {
    const headers = new HttpHeaders().set('XAuth-Token', Store.get('token'));
    return this.http.get(`${environment.api_url}api/voice/getusersbytargetgroup?target_group_id=${target_gp_id}&fs_timestamp=0`, {headers});
  }
}
