import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { TargetGroupComponent } from './target-group/target-group.component';
import { GranteeComponent } from './grantee/grantee.component';
import { UserComponent } from './user/user.component';
import { NavComponent } from './nav/nav.component';
import { RoutingModule } from './routing.module';
import { GrantComponent } from './grant/grant.component';
import { MediaComponent } from './media/media.component';
import { PublicationsComponent } from './publications/publications.component';
import { SuccessStoriesComponent } from './success-stories/success-stories.component';
import { DriveComponent } from './drive/drive.component';
import { ProfilesComponent } from './profiles/profiles.component';
import { StatsComponent } from './stats/stats.component';
import { TileComponent } from './tile/tile.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    TargetGroupComponent,
    GranteeComponent,
    UserComponent,
    NavComponent,
    GrantComponent,
    MediaComponent,
    PublicationsComponent,
    SuccessStoriesComponent,
    DriveComponent,
    ProfilesComponent,
    StatsComponent,
    TileComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    FormsModule,
    RoutingModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
