import {EventEmitter, Injectable} from '@angular/core';
import { Store } from '../store';

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  constructor() {
    this.lgbtQn$ = new EventEmitter();
    this.lgbtAns$ = new EventEmitter();
  }

  lgbtQn$: EventEmitter<any> ;
  lgbtAns$: EventEmitter<any> ;

  getFileExtension(base64String) {
    const parts = base64String.split(';');
    console.log('the parts ::', parts[0]);

    return parts[0].split('/')[1];
  }

  getFileExtension_revised(file: File) {
    const parts = file.name.split('.');

    return parts[parts.length - 1];
  }

  cleanUpBase64(dirtyString) {
    const parts = dirtyString.split(',');
    console.log('the dirty part ::', parts[0]);
    return parts[1];
  }

  getFileType(file: File) {
    return file.type.split('/')[0];
  }

  getUserRights() {
    const usr = Store.getObject('user');
    if (usr.user_rights_ids_string) {
      return usr.user_rights_ids_string.split(',');
    } else {
      return [];
    }
  }

  getUserLevelId(): number {
    const usr = Store.getObject('user');
    if (usr.user_level_id) {
      return Number(usr.user_level_id);
    } else {
      return 3;
    }
  }

}
