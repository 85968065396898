import { Component, OnInit } from '@angular/core';
import { GranteesService } from '../providers/grantees.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-profiles',
  templateUrl: './profiles.component.html',
  styleUrls: ['./profiles.component.css']
})
export class ProfilesComponent implements OnInit {

  constructor(
    private granteeService: GranteesService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer
  ) { }

  loading = false;
  grantees: any;
  document: any;
  document_url: any;

  ngOnInit() {
    this.getGrantees();
  }

  getGrantees() {
    this.loading = true;
    this.granteeService.get().subscribe(
      (data) => {
        this.loading = false;
        this.grantees = data;
        console.log('The grantees ::', data);
      },
      (err) => {
        this.loading = false;
        this.toastr.error(err.error.Message, 'Http Error !!');
      }
    );
  }

  viewDoc(content, url) {
    console.log('The url ::', url);
    if (url !== null && url !== '') {
      this.document_url = this.sanitizer.bypassSecurityTrustResourceUrl(encodeURI(`https://docs.google.com/gview?url=${url}&embedded=true`));
      this.modalService.open(content, {size: 'lg'});
    } else {
      this.toastr.error('Grantee has no profile');
    }
  }

}
