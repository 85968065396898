import * as crypto from 'crypto-js';
import { environment } from '../environments/environment';

export class Store {

  static add(key: string, value: string): void {
    localStorage.setItem('voice-' + key, crypto.AES.encrypt(value, environment.secret).toString());
  }
  static addObject(key: string, value: any): void {
    localStorage.setItem('voice-' + key, crypto.AES.encrypt(JSON.stringify(value), environment.secret));
  }
  static get(key: string): string {
    const data = localStorage.getItem('voice-' + key);
    return (data == null) ? null : crypto.AES.decrypt(data, environment.secret).toString(crypto.enc.Utf8);
  }
  static getObject(key: string): any {
    const data = localStorage.getItem('voice-' + key);
    return (data == null) ? null : JSON.parse(crypto.AES.decrypt(data, environment.secret).toString(crypto.enc.Utf8));
  }
  static remove(key: string): void {
    localStorage.removeItem('voice-' + key);
  }
  static clear(): void {
    localStorage.clear();
  }

}
