import { Component, OnInit } from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(
    private toastr: ToastrService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer
  ) { }

  document_url: any;
  header = 'LINKING AND LEARNING IS THE HEART AND SOUL OF VOICE';
  header_en = 'LINKING AND LEARNING IS THE HEART AND SOUL OF VOICE';
  header_lug = 'OKUYUNGA N\'OKUYIGA GWE MUTIMA N\'OMWOYO GWA VOICE';
  header_swa = 'KUUNGANISHA NA KUJIFUNZA NI MWOYO NA NAFSI YA VOICE ';

  ngOnInit() {
  }

  language_switched(lang) {
    switch (lang) {
      case 'swahili':
        this.header = this.header_swa;
        break;
      case 'luganda':
        this.header = this.header_lug;
        break;
      default:
        this.header = this.header_en;
        break;
    }
  }

  viewDoc(content, url) {
    console.log('The url ::', url);
    if (url !== null && url !== '') {
      this.document_url = this.sanitizer.bypassSecurityTrustResourceUrl(encodeURI(`https://docs.google.com/gview?url=${url}&embedded=true`));
      this.modalService.open(content, {size: 'lg'});
    } else {
      this.toastr.error('Grantee has no profile');
    }
  }

}
