import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { CoreService } from '../providers/core.service';
import { ResourceService } from '../providers/resource.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.css']
})
export class TileComponent implements OnInit {

  constructor(
    private coreService: CoreService,
    private resourceService: ResourceService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private sanitizer: DomSanitizer
  ) { }

  @Input() resource;
  loading = false;
  document: any;
  document_url: any;
  modalReference: any;
  filter: any;
  preview: any;
  fType = 'file';
  user_rights = [];
  comments = [];
  comment: string;
  renamer = {
    resource_id: '',
    old_resource_name: '',
    new_resource_name: ''
  };

  ngOnInit() {
    this.user_rights = this.coreService.getUserRights();
  }

  open(content) {
    this.preview = null;
    this.modalReference = this.modalService.open(content);
  }

  viewDoc(content) {
    console.log('The document', this.document);
    // this.document_url = this.sanitizer.bypassSecurityTrustResourceUrl(this.document.resource_url);
    if (this.document.resource_file_format === 19) {
      const parts = this.document.resource_url.split('/');
      const id = parts[parts.length - 1];
      this.document_url = this.sanitizer.bypassSecurityTrustResourceUrl(encodeURI(`https://www.youtube.com/embed/${id}`));
    } else {
      this.document_url = this.sanitizer.bypassSecurityTrustResourceUrl(encodeURI(`https://docs.google.com/gview?url=${this.document.resource_url}&embedded=true`));
    }
    if (this.hasRight('116')) {
      this.modalService.open(content, { size: 'lg'});
      this.resourceViewed();
    }

    // get comments
    this.getComments();
  }

  deleteResource(resource, index) {
    const confirm = window.confirm('Are you sure ?');
    if (confirm) {
      this.loading = true;
      const req = JSON.stringify(resource.resource_id);
      this.resourceService.delete(req).subscribe(
        (data) => {
          this.loading = false;
          this.toastr.success('Resource deleted successfully');
          // this.resources.splice(index, 1);
        },
        (err) => {
          this.loading = false;
          this.toastr.error(err.error.Message, 'Resource delete failed');
        }
      );
    }
  }

  addToDrive(id) {
    this.loading = true;
    const req = JSON.stringify(id);
    this.resourceService.addToDrive(req).subscribe(
      (data) => {
        this.loading = false;
        this.toastr.success('Resource added to drive successfully');
      },
      (err) => {
        this.loading = false;
        this.toastr.error(err.error.Message, 'Failed to add to drive');
      }
    );
  }

  getComments() {
    this.loading = true;
    this.resourceService.getComments(this.resource.resource_id).subscribe(
      (data: any) => {
        this.loading = false;
        console.log('The comments ::', data);
        this.comments = data;
      },
      (err) => {
        this.loading = false;
        this.toastr.error(err.error.Message, 'Comments error !!');
        console.log('Error getting comments ::', err);
      }
    );
  }

  addComment() {
    this.loading = true;
    const req = {
      resource_id: this.resource.resource_id,
      comment: this.comment
    };
    this.resourceService.addComment(req).subscribe(
      (data) => {
        this.loading = false;
        console.log('Add comment resp :: ', data);
        this.comments.push(data);
      },
      (err) => {
        this.loading = false;
        this.toastr.error(err.error.Message, 'Error adding comment !!');
        console.log('Error adding comment :: ', err);
      }
    );
  }

  resourceViewed() {
    const req = JSON.stringify(this.document.resource_id);
    this.resourceService.resourceViewed(req).subscribe(
      (data) => {
        console.log('Resource viewed ::', data);
      },
      (err) => {
        this.toastr.error(err.error.Message, 'Resource viewed error !!');
      }
    );
  }

  resourceDownloaded() {
    const req = JSON.stringify(this.document.resource_id);
    this.resourceService.resourceDownloaded(req).subscribe(
      (data) => {
        console.log('Resource downloaded ::', data);
      },
      (err) => {
        this.toastr.error(err.error.Message, 'Resource downloaded error !!');
      }
    );
  }

  hasRight(right_id: string): boolean {
    if (this.user_rights.indexOf(right_id) > -1) {
      return true;
    }

    return false;
  }

  renameResource() {
    this.loading = true;
    this.renamer.resource_id = this.document.resource_id;
    this.renamer.old_resource_name = this.document.resource_file_name;
    this.renamer.new_resource_name = this.renamer.new_resource_name + this.document.resource_file_extension;
    // console.log('The new resource ::', this.renamer);
    this.resourceService.rename(this.renamer).subscribe(
      (data: any) => {
        // console.log('the return ::', data);
        this.loading = false;
        this.modalReference.close();
        this.toastr.success('Success', 'Resource was renamed successfully');
      },
      (err) => {
        this.loading = false;
        // console.log('Rename failed ::', err);
        this.toastr.error('Error !!', err.statusText);
      }
    );
  }

}
