import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Store } from '../store';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpClient
  ) { }

  get() {
    const headers = new HttpHeaders().set('XAuth-Token', Store.get('token'));
    return this.http.get(`${environment.api_url}api/voice/getallusers`, {headers});
  }

  add(user) {
    const headers = new HttpHeaders().set('XAuth-Token', Store.get('token'));
    return this.http.post(`${environment.api_url}api/voice/adduser`, user, {headers});
  }

  addAdmin(user) {
    const headers = new HttpHeaders().set('XAuth-Token', Store.get('token'));
    return this.http.post(`${environment.api_url}api/voice/addsubadmin`, user, {headers});
  }

  edit(user) {
    const headers = new HttpHeaders().set('XAuth-Token', Store.get('token'));
    return this.http.post(`${environment.api_url}api/voice/edituser`, user, {headers});
  }

  delete(user) {
    const headers = new HttpHeaders({
      'XAuth-Token': Store.get('token'),
      'Content-Type': 'application/json'
    });
    return this.http.post(`${environment.api_url}api/voice/deleteuser`, user, {headers});
  }

  userRights(user_id) {
    const headers = new HttpHeaders().set('XAuth-Token', Store.get('token'));
    return this.http.get(`${environment.api_url}api/Voice/GetUserRightsTree?user_id=${user_id}`, {headers});
  }

  updateUserRights(data) {
    const headers = new HttpHeaders().set('XAuth-Token', Store.get('token'));
    return this.http.post(`${environment.api_url}api/voice/updatepcuserrights`, data, {headers});
  }
}
