import { Component, OnInit } from '@angular/core';
import { ResourceService } from '../providers/resource.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.css']
})
export class StatsComponent implements OnInit {

  constructor(
    private resourceService: ResourceService,
    private toastr: ToastrService
  ) { }

  loading = false;
  userStats = [];
  granteeStats = [];
  start_date: any;
  end_date: any;

  ngOnInit() {
    // this.getUserStats();
    // this.getGranteeStats();
    console.log('The date ::', new Date().toISOString());
  }

  getUserStats() {
    const sd = new Date();
    sd.setFullYear(this.start_date.year);
    sd.setMonth(this.start_date.month - 1);
    sd.setDate(this.start_date.day);
    const ed = new Date();
    ed.setFullYear(this.end_date.year);
    ed.setMonth(this.end_date.month - 1);
    ed.setDate(this.end_date.day);
    const req = {
      // start_date: '2018-12-12T17:00:49.373',
      start_date: sd.toISOString(),
      // end_date: '2018-12-14T17:00:49.373',
      end_date: ed.toISOString(),
      filter_id: 0,
      fs_timestamp: 0
    };
    this.loading = true;
    this.resourceService.userStats(req).subscribe(
      (data: any) => {
        this.loading = false;
        const ctx = this;
        this.userStats = [];
        data.forEach(function(val) {
          if (val.total_count > 0) {
            ctx.userStats.push(val);
          }
        });
      },
      (err) => {
        this.loading = false;
        this.toastr.error(err.error.Message, 'Error getting stats !!');
      }
    );
  }

  getGranteeStats() {
    const sd = new Date();
    sd.setFullYear(this.start_date.year);
    sd.setMonth(this.start_date.month - 1);
    sd.setDate(this.start_date.day);
    const ed = new Date();
    ed.setFullYear(this.end_date.year);
    ed.setMonth(this.end_date.month - 1);
    ed.setDate(this.end_date.day);
    const req = {
      start_date: sd.toISOString(),
      end_date: ed.toISOString(),
      filter_id: 0,
      fs_timestamp: 0
    };
    this.loading = true;
    this.resourceService.granteeStats(req).subscribe(
      (data: any) => {
        this.loading = false;
        console.log('THe grantee stats ::', data);
        this.granteeStats = [];
        const ctx = this;
        data.forEach(function(val) {
          if (val.total_count > 0) {
            ctx.granteeStats.push(val);
          }
        });
      },
      (err) => {
        this.loading = false;
        this.toastr.error(err.error.Message, 'Error getting stats !!');
      }
    );
  }

  test() {
    console.log('The start date ::', this.start_date);
    const sd = new Date();
    sd.setFullYear(this.start_date.year);
    sd.setMonth(this.start_date.month - 1);
    sd.setDate(this.start_date.day);
    const ed = new Date();
    ed.setFullYear(this.end_date.year);
    ed.setMonth(this.end_date.month);
    ed.setDate(this.end_date.day);
    const req = {
      // start_date: '2018-12-12T17:00:49.373',
      start_date: sd.toISOString(),
      // end_date: '2018-12-14T17:00:49.373',
      end_date: ed.toISOString(),
      filter_id: 0,
      fs_timestamp: 0
    };
    console.log('The final start date ::', sd.toISOString());
  }

}
