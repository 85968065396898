import { Component, OnInit } from '@angular/core';
import { GrantsService } from '../providers/grants.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Grant } from '../models/grant';
import { Store } from '../store';
import { ToastrService } from 'ngx-toastr';
import { CoreService } from '../providers/core.service';

@Component({
  selector: 'app-grant',
  templateUrl: './grant.component.html',
  styleUrls: ['./grant.component.css']
})
export class GrantComponent implements OnInit {

  grants: any;
  grant: Grant = {
    grant_type_name : ''
  };
  loading = false;
  edit = false;
  modalReference: any;
  grantees: any;
  user_rights = [];
  user_level: number;

  constructor(
    private grantService: GrantsService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private coreService: CoreService
  ) { }

  ngOnInit() {
    this.getAllGrants();
    console.log('The user ::', Store.getObject('user'));
    this.user_rights = this.coreService.getUserRights();
    this.user_level = this.coreService.getUserLevelId();
  }

  getAllGrants() {
    this.loading = true;
    this.grantService.getAllGrants().subscribe(
      (data) => {
        this.loading = false;
        console.log('Grant response ::', data);
        this.grants = data;
      },
      (err) => {
        this.loading = false;
        console.log('Error getting grants ::', err);
        this.toastr.error(err.error.Message, 'Error !!');
      }
    );
  }

  save() {
    this.loading = true;
    if (!this.edit) {
      this.grantService.addGrant(this.grant).subscribe(
        (data) => {
          this.loading = false;
          this.toastr.success('Grant added successfully');
          this.modalReference.close();
          // console.log('Adding grant resp ::', data);
          this.grants.push(data);
        },
        (err) => {
          this.loading = false;
          this.toastr.error(err.error.Message, 'Http Error !');
        }
      );
    } else {
      this.grantService.edit(this.grant).subscribe(
        (data: any) => {
          this.loading = false;
          this.toastr.success('Update successful');
          this.grant = data;
          this.modalReference.close();
        },
        (err) => {
          this.loading = false;
          this.toastr.error(err.error.Message, 'Http Error !');
        }
      );
    }
  }

  deleteGrant(gr, index) {
    const confirm = window.confirm('Are you sure ?');
    if (confirm) {
      this.loading = true;
      const ed = JSON.stringify(gr.grant_type_id);
      this.grantService.delete(ed).subscribe(
        (data) => {
          this.loading = false;
          if (data) {
            this.toastr.success('Delete successful');
            this.grants.splice(index, 1);
          } else {
            this.toastr.error('Delete failed');
          }
        },
        (err) => {
          console.log('The err ::', err);
          this.loading = false;
          this.toastr.error(err.error.Message, 'Http Error !');
        }
      );
    }
  }

  open(content) {
    // if not edit mode, show empty fields
    if (!this.edit) {
      this.grant = {
        grant_type_name : ''
      };
    }
    /*this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then(
      (results) => {
        console.log('Modal closed');
      },
      (reason) => {
        console.log('Closed with reason :', reason);
      }
    );*/
    this.modalReference = this.modalService.open(content);
  }

  getGrantGrantees(content, grant_type_id) {
    this.modalService.open(content);
    this.loading = true;
    this.grantees = null;
    this.grantService.granteesByGrantType(grant_type_id).subscribe(
      (data) => {
        this.loading = false;
        console.log('Grant grantees resp ::', data);
        this.grantees = data;
      },
      (err) => {
        this.loading = false;
        this.toastr.error(err.error.Message, 'Failed to get grant grantees');
      }
    );
  }

  hasRight(right_id: string): boolean {
    if (this.user_rights.indexOf(right_id) > -1) {
      return true;
    }

    return false;
  }

}
