import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from '@angular/router';

import { HomeComponent } from './home/home.component';
import { TargetGroupComponent } from './target-group/target-group.component';
import { GranteeComponent } from './grantee/grantee.component';
import { GrantComponent } from './grant/grant.component';
import { LoginComponent } from './login/login.component';
import { UserComponent } from './user/user.component';
import { MediaComponent } from './media/media.component';
import { PublicationsComponent } from './publications/publications.component';
import { SuccessStoriesComponent } from './success-stories/success-stories.component';
import { DriveComponent } from './drive/drive.component';
import { ProfilesComponent } from './profiles/profiles.component';
import { StatsComponent } from './stats/stats.component';

import { AuthGuard } from './auth.guard';

const routes: Routes = [
  {path: 'home', component: HomeComponent, canActivate: [AuthGuard]},
  {path: 'rights-holders', component: TargetGroupComponent, canActivate: [AuthGuard]},
  {path: 'grantees', component: GranteeComponent, canActivate: [AuthGuard]},
  {path: 'grants', component: GrantComponent, canActivate: [AuthGuard]},
  {path: 'login', component: LoginComponent},
  {path: 'users', component: UserComponent, canActivate: [AuthGuard]},
  {path: 'media', component: MediaComponent, canActivate: [AuthGuard]},
  {path: 'publications', component: PublicationsComponent, canActivate: [AuthGuard]},
  {path: 'stories', component: SuccessStoriesComponent, canActivate: [AuthGuard]},
  {path: 'drive', component: DriveComponent, canActivate: [AuthGuard]},
  {path: 'profiles', component: ProfilesComponent, canActivate: [AuthGuard]},
  {path: 'stats', component: StatsComponent, canActivate: [AuthGuard]},
  {path: '', redirectTo: '/home', pathMatch: 'full'}
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {useHash: true})
  ],
  exports: [RouterModule]
})
export class RoutingModule { }
