import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from './providers/login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private loginService: LoginService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // return true;
    const url: string = state.url;
    return this.checkLogin(url);
  }

  checkLogin(url: string): boolean {
    if (this.loginService.isLoggedIn()) {
      // console.log('AuthGuard: user is logged in');
      return true;
    }
    // console.log('AuthGuard: user is not logged in, url ::', url);

    // store attempted url
    this.loginService.redirectUrl = url;

    // redirect to login page
    this.router.navigate(['/login']);

    return false;
  }

}
