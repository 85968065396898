import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from './providers/login.service';
import { Store } from './store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title = 'app';
  loginTime: number;
  timer: any;

  constructor(
    private router: Router,
    private loginService: LoginService
  ) { }

  ngOnInit() {
    // console.log('Initialized init');
    this.loginService.loginEvent$.subscribe(dt => this.loggedIn());
    if (this.loginService.isLoggedIn()) {
      this.loggedIn();
    }
  }

  loggedIn() {
    // console.log('U R L.O.G.G.E.D I.N ::');
    this.loginTime = Number(Store.get('loginTime'));
    this.timer = setInterval(() => {
      const now = new Date().getTime() / 1000 ;
      const diff = (now - this.loginTime) / 60;
      // console.log('The diff ::', diff + ' mins');
      // console.log('The URL ::', this.router.url);
      if (diff > 58) {
        // console.log('Session timed out, login');
        Store.clear();
        this.loginService.redirectUrl = this.router.url;
        this.loginService.redirectUrl = (this.loginService.redirectUrl === '/login') ? '/' : this.loginService.redirectUrl;
        this.router.navigate(['/login']);
      }
    }, 1000);
  }

}
