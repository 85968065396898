import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Store } from '../store';
import {Observable} from "rxjs/index";

@Injectable({
  providedIn: 'root'
})
export class ResourceService {

  constructor(
    private http: HttpClient
  ) { }

  add(data: FormData) {
    const headers = new HttpHeaders().set('XAuth-Token', Store.get('token'));
    return this.http.post(`${environment.api_url}api/voice/addresource`, data, {headers});
  }

  getAllResourcesByGrantee(id: number) {
    const headers = new HttpHeaders().set('XAuth-Token', Store.get('token'));
    return this.http.get(`${environment.api_url}api/voice/getallresourcesbygrantee?grantee_id=${id}&fs_timestamp=0`, {headers});
  }

  getAllResourcesByGranteeResourceType(id: number, resource_type_id: number) {
    const headers = new HttpHeaders().set('XAuth-Token', Store.get('token'));
    return this.http.get(`${environment.api_url}api/voice/getallresourcesbygranteeresourcetype?grantee_id=${id}&resource_type_id=${resource_type_id}&fs_timestamp=0`, {headers});
  }

  /*getAllResourcesByCategory() {
    return null;
  }*/

  tgByGrantee(grantee_id) {
    const headers = new HttpHeaders().set('XAuth-Token', Store.get('token'));
    return this.http.get(`${environment.api_url}api/voice/getalltargetgpbygrantee?grantee_id=${grantee_id}`, {headers});
  }

  delete(rs) {
    const headers = new HttpHeaders({
      'XAuth-Token': Store.get('token'),
      'Content-Type': 'application/json'
    });
    return this.http.post(`${environment.api_url}api/voice/deletevoiceresource`, rs, {headers});
  }

  addToDrive(data) {
    const headers = new HttpHeaders({
      'XAuth-Token': Store.get('token'),
      'Content-Type': 'application/json'
    });
    return this.http.post(`${environment.api_url}api/voice/addresourcetomydrive`, data, {headers});
  }

  getDriveResources() {
    const headers = new HttpHeaders().set('XAuth-Token', Store.get('token'));
    return this.http.get(`${environment.api_url}api/voice/getmydriveresources`, {headers});
  }

  removeDriveResource(data) {
    const headers = new HttpHeaders({
      'XAuth-Token': Store.get('token'),
      'Content-Type': 'application/json'
    });
    return this.http.post(`${environment.api_url}api/voice/removeresourcefrommydrive`, data, {headers});
  }

  resourceViewed(data) {
    const headers = new HttpHeaders({
      'XAuth-Token': Store.get('token'),
      'Content-Type': 'application/json'
    });
    return this.http.post(`${environment.api_url}api/voice/resourceviewednotification`, data, {headers});
  }

  resourceDownloaded(data) {
    const headers = new HttpHeaders({
      'XAuth-Token': Store.get('token'),
      'Content-Type': 'application/json'
    });
    return this.http.post(`${environment.api_url}api/voice/resourcedownloadednotification`, data, {headers});
  }

  userStats(data) {
    const headers = new HttpHeaders().set('XAuth-Token', Store.get('token'));
    return this.http.post(`${environment.api_url}/api/voice/getresourceanalysisrangegroupbyuser`, data, {headers});
  }

  granteeStats(data) {
    const headers = new HttpHeaders().set('XAuth-Token', Store.get('token'));
    return this.http.post(`${environment.api_url}/api/voice/getresourceanalysisrangegranteeuploads`, data, {headers});
  }

  addComment(data) {
    const headers = new HttpHeaders().set('XAuth-Token', Store.get('token'));
    return this.http.post(`${environment.api_url}/api/voice/addresourcecomment`, data, {headers});
  }

  getComments(resource_id) {
    const headers = new HttpHeaders().set('XAuth-Token', Store.get('token'));
    return this.http.get(`${environment.api_url}api/voice/getallcommentsbyresourceid?resource_id=${resource_id}`, {headers});
  }

  deleteComment(cm) {
    const headers = new HttpHeaders({
      'XAuth-Token': Store.get('token'),
      'Content-Type': 'application/json'
    });
    return this.http.post(`${environment.api_url}api/voice/deleteresourcecomment`, cm, {headers});
  }

  rename(data): Observable<any> {
    const headers = new HttpHeaders().set('XAuth-Token', Store.get('token'));
    return this.http.post(`${environment.api_url}api/voice/renamevoiceresource`, data, {headers});
  }
}
